@import '~@/assets/scss/main.scss';

.quiz-status-trophy {
    font-size: 1.5rem;
}

.fa-thin {
    color: transparent !important;
    border-radius: 50%;
    width: 93px;
    height: 93px;
    border: solid $light-gray;
    display: inline-block;
    text-align: center;
}

.btn-perfect-score {
    opacity: 75%;
    @extend .font-raleway;

    &:hover {
        cursor: default;
    }
}

.btn-bottom {
    border-radius: 0rem 0rem $border-radius-card $border-radius-card;
    color: white;
    background-color: v-bind(themeColorHex);
    transition: all $transition-duration;
    &:hover {
        filter: brightness(110%);
    }
}
