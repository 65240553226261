@import '~@/assets/scss/main.scss';

// For adding units to generic value
// See: https://css-tricks.com/snippets/sass/correctly-adding-unit-number/
$flipbook-size-xxl: 85;
$flipbook-size-lg: 70;
$flipbook-size-md: 60;

.flipbook-fullscreen {
    width: $flipbook-size-xxl + 0vw;
    height: $flipbook-size-xxl + 0vh;

    @include media-breakpoint-down(md) {
        height: $flipbook-size-md + 0vh;
    }
}

.flipbook {
    width: $flipbook-size-lg + 0vw;
    height: $flipbook-size-lg + 0vh;

    @include media-breakpoint-down(md) {
        height: $flipbook-size-md + 0vh;
    }
}

// Custom sizing for full screen control icon buttons
.btn-action-bar.btn {
    color: $white;
    font-size: 2rem;
    opacity: 0.8;
    transition: opacity $transition-duration;

    &.disabled {
        opacity: 0.2;
    }

    &:hover {
        opacity: 1;
    }

    @include media-breakpoint-down(md) {
        font-size: 1.5rem;
    }
}

.overlay {
    background-color: #000000e3;
    left: 0;
    overflow: hidden;
    position: fixed;
    transition: $transition-duration;
    top: 0;
    width: 0;
    z-index: 1;
}

.overlay-controls-outer-element {
    width: 3rem;
}

.book-controls {
    gap: 1rem;

    @include media-breakpoint-down(md) {
        gap: 0.25rem;
    }
}
