<template>
    <div class="text-center">
        <loading-indicator v-if="!player"></loading-indicator>
        <h2 id="video-name" class="mb-3">{{ video.name }}</h2>
        <div class="row">
            <div class="col-xl-8 offset-xl-2">
                <div ref="player"></div>
                <p id="video-description" class="mt-2">
                    {{ videoDescription }}
                </p>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, onMounted, onUnmounted } from "vue";

import { AppActionTypes } from "@/store/actions";
import { useStore } from "@/store";

import brightcovePlayerLoader from "@brightcove/player-loader";

import { StudentVideoProgressModel } from "@/models";
import { UserModel, VideoModel } from "@/models";
import { BrightcovePlayer } from "@/video";
import { BRIGHTCOVE_ACCOUNT_ID, BRIGHTCOVE_PLAYER_ID } from "@/configuration";

export default defineComponent({
    props: {
        video: {
            type: Object as PropType<VideoModel>,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        },
        user: {
            type: Object as PropType<UserModel>,
            required: true
        },
        progress: {
            type: Object as PropType<StudentVideoProgressModel>,
            required: true
        }
    },
    setup(props) {
        const store = useStore();

        const player = ref<BrightcovePlayer>({} as BrightcovePlayer);
        const videoDescription = ref("");
        const trackingIntervalId = ref<number>(0);

        // Create brightcove player with given video ID
        const initializeBrightcovePlayer = (): Promise<{
            ref: BrightcovePlayer;
        }> => {
            return brightcovePlayerLoader({
                refNode: player.value as unknown as HTMLElement,
                accountId: BRIGHTCOVE_ACCOUNT_ID,
                playerId: BRIGHTCOVE_PLAYER_ID,
                videoId: props.video.id ?? "",
                embedOptions: {
                    responsive: true
                }
            });
        };

        const stopTrackingProgress = (): void => {
            if (trackingIntervalId.value) {
                clearInterval(trackingIntervalId.value);
                trackingIntervalId.value = 0;
            }
        };

        // Fire tracking on different player events
        const initializeBrightcovePlayerEvents = (
            player: BrightcovePlayer
        ): void => {
            let duration: number;

            /**
             * `playing` event fires when:
             * 1.) Video played
             */
            player.on("playing", () => {
                duration = Math.floor(player.duration());

                trackingIntervalId.value = setInterval(
                    () =>
                        trackProgress(
                            Math.floor(player.currentTime()),
                            duration
                        ),
                    10 * 1000
                );
            });

            /**
             * `pause` event fires when:
             * 1.) Manually paused
             * 2.) After scrubbing
             * 3.) Right as video ends before `ended` event is fired
             */
            player.on("pause", () => stopTrackingProgress());

            /**
             * `ended` event fires when:
             * 1.) `currentTime` equals the end of the media resource; i.e. when video ends
             */
            player.on("ended", () => {
                stopTrackingProgress();
                trackProgress(Math.floor(player.currentTime()), duration);
            });
        };

        const trackProgress = (
            currentTime: number,
            totalTime: number
        ): void => {
            store.dispatch(AppActionTypes.createStudentVideo, {
                model: {
                    id: props.progress?.id,
                    lessonId: props.lessonId,
                    currentTimeInSeconds: currentTime,
                    totalVideoLengthInSeconds: totalTime
                },
                courseId: props.courseId,
                lessonId: props.lessonId
            });
        };

        onMounted(() => {
            initializeBrightcovePlayer().then((container) => {
                const player = container.ref;

                /**
                 * `loadedmetadata` event fires when:
                 * 1.) The player has initial duration and dimension information; i.e. when the first segment is downloaded
                 */
                player.on("loadedmetadata", () => {
                    videoDescription.value = player.mediainfo.description;
                });

                if (
                    props.user.role !== "Student" ||
                    props.progress.isComplete
                ) {
                    return;
                }

                // If there is a saved time, seek to it
                if (props.progress.currentTimeInSeconds) {
                    player.currentTime(props.progress.currentTimeInSeconds);
                }

                initializeBrightcovePlayerEvents(player);
            });
        });

        // make sure tracking is stopped when the component is unmounted - in the event of navigation or other reasons than the video was paused or ended
        onUnmounted(() => stopTrackingProgress());

        return {
            videoDescription,
            player,
            trackingIntervalId
        };
    }
});
</script>
<style>
.vjs-dock-text {
    /*
    https://player.support.brightcove.com/styling/customizing-player-appearance.html
    Hide the video file name (ex: LOH-vid31-ep26-America's_Government...)
    */
    display: none !important;
}
</style>
