@import '~@/assets/scss/main.scss';

.nav-link {
    font-weight: bold;
    color: $black;
}

.tab-content {
    padding: 1rem 2rem;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: #dde6e9;

    @include media-breakpoint-down(lg) {
        padding: 1rem 0.5rem;
    }
}
